import axios from 'axios';
// import qs from 'qs'
import store from '/src/store/index'       //已经设置路径别名，否则相对路径引用
import { Toast } from 'vant';
import {useRouter} from 'vue-router'
import {configPass} from '/src/common/config.js'
let router=useRouter()

let loading = null
const api = axios.create({
    timeout: 30000,                                   //超时时间
    baseURL: "/kkyy",             // 我们在请求接口的时候就不用写前面 会自动我们补全
    // transformRequest: data => qs.stringify(data)    //post请求参数处理,防止post请求跨域
})
// http request 拦截器
api.interceptors.request.use(config => {
    loading = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: 'loading......',
      });
    let token = localStorage.getItem("token");
    console.log(config.url);
    // if("/web/wx/check"==config.url||"/web/customer/customersave"==config.url||"/web/product/productlist"==config.url||"/web/type/typelist"==config.url
    //   ||"/web/news/newsdetail"==config.url||"/web/sms/sms"==config.url||"/app/login"==config.url
    //   ||"/web/order/addsearch"==config.url){
    if(configPass(config)){
      if(token){
        config.headers = {
          "token": token
        }
      }
    }else if(token==undefined||token==null||token==""||token=="undefined"){
        var ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
          location.href="/login"
          // location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe0fd5284c7dad381&redirect_uri=http%3A%2F%2Fep.bjsenztech.com%2Fbind&scope=snsapi_userinfo&state=index&response_type=code#wechat_redirect";
        } else {
          // router.push({ path: '/login' })
          location.href="/login"
        }
        return null;
    }else if (token) {
      config.headers = {
        "token": token
      }
    }
    return config;
    //如果存在jwt，则将jwt添加到每次请求之中..
    // if (store.state.jwt) {
    //     config.params = {
    //         ...config.params,
    //         jwt: store.state.jwt
    //     }
    // }
    // return config
}, err => {
    return err
})
// http response 拦截器
api.interceptors.response.use(response => {
    loading.clear()
    // return response
    //接收返回数据..
    const res = response.data
    if(res.errcode=="5001"){
      localStorage.clear();
      location.href="/login"
      return;
    }
    //判断返回数据是否存在状态code和错误提示信息..
    if (!res.errcode && !res.errmsg) {
        return showMessage(res.errmsg)
    }
    //判断状态code是否为指定数值(200)..
    if (res.errcode != "0") {
        return showMessage(res.errmsg)
    }
    return res
}, err => {
    console.log("33333333333333333")
    

    loading.close()
    if(err.response.data){
      if(err.response.data.errcode=="5001"){
        localStorage.clear();
        location.href="/login"
        return;
      }

        return showMessage(err.response.data.errmsg)
    }
    return showMessage(err.errmsg)
})

//封装错误提示信息..
function showMessage(message, type = "error", showClose = true) {
    if(type=="error"){
        Toast.fail(message);
      }else{
        Toast.success(success);
      }
    return Promise.reject()
}
export default api;
