<template>
    <div class="menu">
      <div @click="clickMenu(1)" :class="'menu-item'+(item==1?' on':'')">
        <img v-if="item==1" src="../assets/img/menu/mn_index_sel.png"/>
        <img v-if="item!=1" src="../assets/img/menu/mn_index.png"/>
        <div>首页</div>
      </div>
      <!-- <div @click="clickMenu(2)" :class="'menu-item'+(item==2?' on':'')">
        <img v-if="item==2" src="../assets/img/menu/mn_customer_sel.png"/>
        <img v-if="item!=2" src="../assets/img/menu/mn_customer.png"/>
        <div>客户</div>
      </div>
      <div @click="clickMenu(3)" :class="'menu-item'+(item==3?' on':'')">
        <img v-if="item==3" src="../assets/img/menu/mn_team_sel.png"/>
        <img v-if="item!=3" src="../assets/img/menu/mn_team.png"/>
        <div>团队</div>
      </div> -->
      <div @click="clickMenu(4)" :class="'menu-item'+(item==4?' on':'')">
        <img v-if="item==4" src="../assets/img/menu/mn_me_sel.png"/>
        <img v-if="item!=4" src="../assets/img/menu/mn_me.png"/>
        <div>我的</div>
      </div>
    </div>
</template>
<script setup>
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import {useRoute,useRouter} from 'vue-router'

let route=useRoute()
let router=useRouter()

const props=defineProps({
    emodel:{}
})
const item=props.emodel.index;

function clickMenu(cur){
  // if(cur==item){
  //   return;
  // }
  if(cur==1){
    router.push({ path: '/bxindex'})
  }else if(cur==2){
    router.push({ path: '/customer'})
  }else if(cur==3){
    router.push({ path: '/team'})
  }else if(cur==4){
    router.push({ path: '/bxme'})
  }
}
console.log("======"+item)
</script>

<style scoped lang="scss">
.menu{
  max-width: 30rem;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom:0.5rem;
  padding-top:1rem;
  z-index: 999;
  background: #fff;
  display: flex;
  font-size:0.9rem;
  .menu-item{
    flex:1;
    color:#949999;
    text-align: center;
    background: #fff;
    img{
      width:1.8rem;
    }
    &.on{
        color:#3D6FFF;
    }
  }
}
</style>