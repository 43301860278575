<template>
  	<div v-if="data.shareShow" class="bback" @click="data.shareShow=false">
	</div>
  	<div v-if="data.shareShow" class="pop-share" style="height:90%;">
		<div class="pop-close" @click="data.shareShow=false">×</div>
		<div class="pop-con">
      <div class="pop-wtitle" style="margin-left:1rem;margin-right:1rem;">
        <div class="pop-title" style="text-align: center;">推荐还有进件赚佣金</div>
        <div class="pop-title-sub" style="text-align: center;">长按海报即可分享好友</div>
        <img style="width: 100%;" :src="data.imgUrl"/>
        <div class="pop-ewm">
          <div class="pop-ewm-desc">
            <div style="color:#949999">分享图有异常？</div>
            <img @click="refreshShare()" src="@/assets/img/home/icon_refresh.png"/>
            <div @click="refreshShare()">点我刷新</div>
          </div>
        </div>
        
      </div>
		</div>
	</div>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../common/http.js';
import {isNull,nullStr,showMessage} from '../common/utils.js'
let data=reactive({
  shareShow:false,
  imgUrl:""
  // imgUrl:require('@/assets/img/home/share.png'),
})
let params={}

function showShare(item){
    if("productlist"==item.type){
        params.shareType="04";
    }else if("product"==item.type){
        params.shareType="01";
        params.productCode=item.model.productCode;
        params.productChannelCode=item.model.productChannelCode;
    }else if("order"==item.type){
        params.shareType="06";
        params.productCode=item.model.productCode;
        params.productChannelCode=item.model.productChannelCode;
        params.orderCode=item.model.orderCode;
    }else if("match"==item.type){
        params.shareType="07";
        params.productCode=item.model.productCode;
        params.productChannelCode=item.model.productChannelCode;
        params.matchCode=item.model.matchCode;
        params.matchProductCode=item.model.matchProductCode;
    }else if("calc"==item.type){
        params.shareType="05";
    }
    refreshShare();
}

function refreshShare(){
    api.post("/web/pick/code",params).then((res)=>{
        if(res.errcode=="0"){
          data.imgUrl=res.image;
        }else{
          showMessage(res.errmsg);
        }
    })
    data.shareShow=true;
}

defineExpose({showShare})
</script>

<style scoped lang="scss">
.pop-share{
  height: 57%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .pop-ewm{
    text-align: center;
    margin-top: 1rem;
    .pop-ewm-img{
      width:8rem;
    }
    .pop-ewm-desc{
      display:flex;
      justify-content:center;
      margin-top:0.5rem;
      img{
        margin-top:0.1rem;
        // width:1rem;
        height:0.8rem;
      }
      div{
        margin-left:0.3rem;
        color:#3a6df9;
        font-size: 0.8rem;
      }
    }
  }

  .pop-con{
    margin-left: 1rem;
    margin-right: 1rem;

    .mt{
      margin-top:0.5rem;
    }
    .pop-rel{
      position: relative;
      .pop-sel{
        position: absolute;
        border: 1px solid #949999;
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fff;
        z-index: 999;
        right: 0px;
        left: 0px;
        .pop-sel-div{
          display: flex;
          height:2.5rem;
          justify-content: center;
          &.bot{
            border-bottom: 1px solid #949999;
          }
          .pop-sel-div-rad{
            width:1rem;
            height:1rem;
            margin-top: 0.75rem;
          }
          .pop-sel-div-lab{
            line-height:2.5rem;
            margin-left: 1rem;;
          }
        }
      }
    }
    .field-div{
      border: 1px solid #949999;
      border-radius: 2rem;
      padding-left: 1rem;
      display: flex;
      align-items:center;
      .field-sp{
        width:1px;
        background: #ececec;
        height: 1rem;
        margin-left:0.8rem;
      }
      .field-div-label{
        margin:0.8rem;
        color:#afb4bd;
      }
      .field-unit-sub{
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
        display: flex;
        .field-sp-sub{
          width:1px;
          background: #ececec;
          height: 1rem;
          margin-top:0.8rem;
        }
        .dt2-sj-b{
            margin-top: 1.3rem;
            width: 100%;
            text-align: center;
            justify-content: center;
            justify-items: center;
            display: flex;
        }
        .dt2-sj{
            width: 0px;
            height: 0px;
            border: 0.4rem solid transparent;
            border-top-color: #949999;
        }
      }
      .field-unit{
        background: #929898;
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
      }
      img{
        width: 1rem;
        height: 1.3rem;
        vertical-align: middle;
      }
    }
  }
  
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-wtitle{
    margin-left: 3rem;
    margin-right: 3rem;
    .pop-title{
      font-size: 1rem;
      font-weight: bold;
    }
    .pop-sp{
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      height: 1px;
      background: #ebebeb;
    }
    .pop-title-sub{
      color:#949999;
      margin-bottom:0rem;
      font-size: 0.9rem;
    }
    
    .pop-title-label{
      margin-bottom:1rem;
      .pop-title-label-city{
        border: 1px solid #ebebeb;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        float: left;
        margin-top: 0.5rem;
      }
      .clear{
        clear: both;
      }
    }
  }
  .pop-sph{
    height: 0.3rem;
    background: #ebebeb;
  }
  .pop-picker{
    
  }
  .pop-btn{
    margin:1rem;
  }
}
</style>